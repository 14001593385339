import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { Rating } from '../../interfaces/supplier.interface';
import { ReviewCreated, ReviewUpdated } from '../../interfaces/review.interface';
import { ReviewsService } from '../../services/reviews.service';
import { SuppliersService } from '../../services/suppliers.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrl: 'rating.component.scss',
  providers: [MessageService, ConfirmationService]
})
export class RatingComponent implements OnInit {
  @Input() supplierId!: number;
  @Input() rating: Rating | null = null;
  @Output() closeRatingDialog = new EventEmitter<boolean>();

  title: string = 'Nueva Calificación';
  displayDialog: boolean = true;

  form: FormGroup = this.formBuilder.group({
    rating: [null, Validators.required],
    review: ['', Validators.required],
  });

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly reviewsService: ReviewsService,
    private readonly suppliersService: SuppliersService,
    private readonly messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.title = this.rating ? 'Editar Calificación' : 'Nueva Calificación';
    if (this.rating) {
      this.form.patchValue({
        rating: this.rating.number,
        review: this.rating.review?.description || '',
      });
    }
  }


  closeDialog(success: boolean): void {
    this.displayDialog = false;
    this.closeRatingDialog.emit(success);
  }


  save(): void {
    const formValue = this.form.value;

    if (!formValue.rating) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Debe seleccionar al menos una estrella antes de guardar la calificación.',
        life: 2000,
      });
      return;
    }

    if (this.rating) {
      this.suppliersService.modifyRating(this.supplierId, this.rating.id, formValue.rating).subscribe({
        next: () => {
          if (this.rating?.review && formValue.review) {
            const reviewBody: ReviewUpdated = { description: formValue.review };
            this.reviewsService.update(this.rating.review.id, reviewBody).subscribe({
              next: () => {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Éxito',
                  detail: 'Calificación actualizada con éxito!',
                  life: 3000,
                });
                this.closeDialog(true);
              },
              error: (response: any) => this.handleErrorResponse(response)
            });
          } else {
            this.messageService.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'Calificación actualizada con éxito!',
              life: 3000,
            });
            this.closeDialog(true);
          }
        },
        error: (response: any) => this.handleErrorResponse(response)
      });
    } else {
      if (formValue.review) {
        const reviewBody: ReviewCreated = {
          description: formValue.review,
          supplierId: this.supplierId
        };

        this.reviewsService.create(reviewBody).subscribe({
          next: (response: any) => {
            const addReviewBody = {
              ratingId: formValue.rating,
              reviewId: response.reviewId,
            };

            this.suppliersService.addRating(this.supplierId, addReviewBody).subscribe({
              next: () => {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Éxito',
                  detail: 'Calificación creada con éxito!',
                  life: 2000,
                });
                this.closeDialog(true);
              },
              error: (response: any) => this.handleErrorResponse(response)
            });
          },
          error: (response: any) => this.handleErrorResponse(response)
        });
      } else {
        const addRatingBody = {
          ratingId: formValue.rating,
        };

        this.suppliersService.addRating(this.supplierId, addRatingBody).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'Calificación creada con éxito!',
              life: 2000,
            });
            this.closeDialog(true);
          },
          error: (response: any) => this.handleErrorResponse(response)
        });
      }
    }
  }


  private handleErrorResponse(response: any): void {
    if (response?.error && typeof response.error === 'string') {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: response.error,
        life: 3000,
      });
    } else if (response?.error?.error && typeof response.error.error === 'string') {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: response.error.error,
        life: 3000,
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Ocurrió un error al procesar la solicitud.',
        life: 1500,
      });
    }
  }
}
