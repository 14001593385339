<section class="login-wrapper">
  <div class="login-grid">
    <div class="login-image"></div>
    <div class="login-form">
      <div class="form-container">
        <div class="logo-container">
          <img
            src="../../../assets/img/transparent-logo-blue.png"
            alt="logo-asei"
            />
        </div>
        <asei-notifications [notifications]="notifications"></asei-notifications>
        <div class="main-container">
          <div class="title">
            <h1>Bienvenido</h1>
          </div>
          <form (ngSubmit)="login(fLogin)" #fLogin="ngForm" class="form">
            @if (showLogin) {
              <mat-form-field appearance="fill">
                <input
                  matInput
                  placeholder="Correo electrónico"
                  type="email"
                  name="email"
                  [(ngModel)]="loginUser.email"
                  required
                  />
              </mat-form-field>

              <mat-form-field class="input">
                <input
                  matInput
                  [type]="showPassword ? 'text' : 'password'"
                  placeholder="Contraseña"
                  name="password"
                  [(ngModel)]="loginUser.password"
                  />
                <button
                  mat-icon-button
                  matSuffix
                  (click)="togglePassword()"
                  type="button"
                  >
                  <mat-icon>{{ passwordToggleIcon }}</mat-icon>
                </button>
              </mat-form-field>
              <mat-checkbox class="example-margin" [(ngModel)]="loginUser.remember" name="remember">Recuerdame</mat-checkbox>
            }

            <div class="container_button_login">
              @if (!showLogin) {
                <button
                  mat-button
                  class="button button-mt {{ isShowLoaderButton && 'disabled' }}"
                  color="primary"
                  type="button"
                  (click)="rememberLogin()"
                  [disabled]="isShowLoaderButton"
                >
                  <div class="button_container">
                    Acceso Rápido
                    @if (isShowLoaderButton) {
                      <mat-icon>
                        <mat-spinner
                          color="secondary"
                          diameter="20"
                        ></mat-spinner>
                      </mat-icon>
                    }
                  </div>
                </button>
                <p>o</p>
                <button
                  mat-button
                  class="button {{ isShowLoaderButton && 'disabled' }}"
                  color="primary"
                  type="button"
                  (click)="showLoginForm()"
                  [disabled]="isShowLoaderButton"
                  >
                  <div class="button_container">
                    Iniciar Sesión
                  </div>
                </button>
              }
              @if (showLogin) {
                <button
                  mat-button
                  class="button {{ isShowLoaderButton && 'disabled' }}"
                  [disabled]="isShowLoaderButton"
                  color="primary"
                  type="submit"
                  >
                  <div class="button_container">
                    Iniciar Sesión
                    @if (isShowLoaderButton) {
                      <mat-icon>
                        <mat-spinner
                          color="secondary"
                          diameter="20"
                        ></mat-spinner>
                      </mat-icon>
                    }
                  </div>
                </button>
                <button
                  mat-button
                  class="button button-mt {{ isShowLoaderButton && 'disabled' }}"
                  (click)="backRemember()"
                  color="primary"
                  type="button"
                >
                  <div class="button_container">Regresar</div>
                </button>
              }
            </div>
            @if (showLogin) {
              <div class="reset-pass-box">
                <a href="/solicitud-restablecer-contrasena">¿Olvidaste tu contraseña?</a>
              </div>
            }
          </form>
        </div>
        <div class="social-container">
          <div class="box">
            <p>Nuestras redes sociales</p>
            <div class="social-links">
              <a href="https://www.facebook.com/aseiperu" target="_blank">
                <img src="assets/img/socials/Facebook.svg" alt="Mi icono SVG">
              </a>
              <a href="https://instagram.com/asei_empresas_inmobiliarias?igshid=cpalbx63bz3y" target="_blank">
                <img src="assets/img/socials/Instagram.svg" alt="Mi icono SVG">
              </a>
              <a href="https://www.linkedin.com/company/asociación-de-empresas-inmobiliarias-del-perú" target="_blank">
                <img src="assets/img/socials/Linkedin.svg" alt="Mi icono SVG">
              </a>
              <a href="https://www.youtube.com/channel/UCXvcyiKkSTeHkscjlX4khJw" target="_blank">
                <img src="assets/img/socials/Youtube.svg" alt="Mi icono SVG">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
