<p-dialog
  [header]="title"
  [(visible)]="displayDialog"
  [modal]="true"
  [closable]="false"
  [resizable]="false"
  [draggable]="false"
  [position]="'center'"
  [baseZIndex]="2100"
  [contentStyle]="{ 'min-height': '18rem', 'overflow': 'auto' }"
  [styleClass]="'custom-dialog'"
>
  <ng-template pTemplate="header">
    <span class="popup-title">{{ title }}</span>
    <button type="button" class="close-button" (click)="closeDialog(false)">
      &times;
    </button>
  </ng-template>

  <ng-template pTemplate="content">
    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="input-box">
        <label for="rating-input">Calificación</label>
        <p-rating
          id="rating-input"
          formControlName="rating"
          [stars]="5"
          [cancel]="false"
        ></p-rating>
      </div>

      <div class="input-box">
        <label for="review-input">Comentario</label>
        <textarea
          id="review-input"
          name="review"
          formControlName="review"
          placeholder="Escribe tu comentario aquí..."
        ></textarea>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button class="btn secondary" type="button" (click)="closeDialog(false)">Regresar</button>
    <button class="btn primary" type="submit" [disabled]="form.invalid" (click)="save()">Guardar</button>
  </ng-template>
  <p-toast></p-toast>
</p-dialog>


