import { Component, OnInit, ViewChild } from '@angular/core';
import { BlacklistService } from "../../services/blacklist.service";
import { Blacklist } from "../../../../interfaces/blacklist.interface";
import { MessageService } from "primeng/api";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import UserDataLogin from "../../../../interfaces/user-data-login.interface";

@Component({
  selector: 'blacklist-list',
  templateUrl: './blacklist-list.component.html',
  styleUrls: ['./blacklist-list.component.scss'],
  providers: [MessageService]
})
export class BlacklistListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  isCreateEditPopupVisible = false;
  isTogglePopupVisible = false;
  isEditMode = false;
  selectedWord = '';
  selectedId: number | null = null;
  selectedElement: Blacklist | null = null;
  blacklist: Blacklist[] = [];
  filteredBlacklist: Blacklist[] = [];
  searchTerm: string = '';
  notifications: Map<string, string> = new Map<string, string>();
  private tempIsEditMode = false;
  private tempWord = '';
  private tempId: number | null = null;
  private tempElement: Blacklist | null = null;
  totalItems = 0;
  currentPage = 1;
  perPage = 10;
  type = ''; // 'Activos', 'Inactivos', 'Todos', or ''
  pageSizeOptions = [5, 10, 20];
  isAdmin = false;
  statusFilter = ''; // 'active', 'inactive', 'all', or ''
  slugFilter: string = '';
  filter: any = {};

  constructor(
    private blacklistService: BlacklistService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    window.addEventListener('beforeunload', this.clearLocalStorageOnReload);

    const savedFilters = localStorage.getItem('blacklistFilters');
    if (savedFilters) {
      this.filter = JSON.parse(savedFilters);
      this.searchTerm = this.filter.searchTerm || '';
      this.statusFilter = this.filter.statusFilter || '';
      this.type = this.filter.type || '';
      this.currentPage = this.filter.currentPage || 1;
      this.perPage = this.filter.perPage || 10;
    }

    const data = this.getUserData();
    this.isAdmin = data?.roles.some((rol) => rol.name === 'admin') || false;

    if (this.statusFilter !== 'active' && this.statusFilter !== 'inactive' && this.statusFilter !== 'all') {
      this.statusFilter = '';
      this.type = '';
    }

    if (this.statusFilter === 'active' || this.statusFilter === 'inactive' || this.statusFilter === 'all') {
      this.filterByStatus(this.statusFilter);
    } else {
      this.loadBlacklist(this.currentPage, this.perPage, this.searchTerm);
    }
  }

  clearLocalStorageOnReload(): void {
    localStorage.removeItem('blacklistFilters');
  }

  getUserData(): UserDataLogin | undefined {
    const jsonData = localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    return userData?.data;
  }

  loadBlacklist(page: number = this.currentPage, per_page: number = this.perPage, search: string = this.searchTerm) {
    this.blacklistService.findAll(page, per_page, search).subscribe({
      next: (response) => {
        this.blacklist = response.data || [];
        this.filterCurrentList();
        if (response.meta) {
          this.totalItems = response.meta.total || 0;
          this.currentPage = response.meta.current_page || 1;
          this.perPage = response.meta.per_page || 10;
        }
        this.saveFilters();
      },
      error: () => {
        this.showError('Error al cargar la lista.');
      }
    });
  }

  loadAllBlacklistData(status: string) {
    let allData: Blacklist[] = [];
    let page = 1;
    const fetchData = () => {
      this.blacklistService.findAll(page, this.perPage, this.searchTerm).subscribe({
        next: (response) => {
          allData = allData.concat(response.data || []);
          if (response.meta && response.meta.current_page < response.meta.last_page) {
            page++;
            fetchData();
          } else {
            this.blacklist = allData;
            this.filterCurrentList();
            this.saveFilters();
          }
        },
        error: () => {
          this.showError('Error al cargar la lista.');
        }
      });
    };
    fetchData();
  }

  filterByStatus(status: string) {
    this.statusFilter = status;
    if (status === 'active') {
      this.type = 'Activos';
      this.filteredBlacklist = this.isAdmin ? this.blacklist : this.blacklist.filter(item => item.isActive);
    } else if (status === 'inactive') {
      this.type = 'Inactivos';
      this.filteredBlacklist = this.isAdmin ? this.blacklist : this.blacklist.filter(item => !item.isActive);
    } else if (status === 'all') {
      this.type = 'Todos';
      this.filteredBlacklist = this.blacklist;
    } else {
      // No filter
      this.type = '';
      this.filteredBlacklist = this.blacklist;
    }
    this.saveFilters();
    this.loadAllBlacklistData(status);
  }

  filterCurrentList() {
    if (!this.isAdmin) {
      if (this.statusFilter === 'active') {
        this.filteredBlacklist = this.blacklist.filter(item => item.isActive);
      } else if (this.statusFilter === 'inactive') {
        this.filteredBlacklist = this.blacklist.filter(item => !item.isActive);
      } else {
        // No filter
        this.filteredBlacklist = this.blacklist;
      }
    } else {
      if (this.statusFilter === 'all') {
        this.filteredBlacklist = this.blacklist;
      }
      else if (this.statusFilter === 'active') {
        this.filteredBlacklist = this.blacklist.filter(item => item.isActive);
      }
      else if (this.statusFilter === 'inactive') {
        this.filteredBlacklist = this.blacklist.filter(item => !item.isActive);
      }
      else {
        // No filter
        this.filteredBlacklist = this.blacklist;
      }
    }
  }

  applySearch() {
    this.currentPage = 1;
    this.loadBlacklist(this.currentPage, this.perPage, this.searchTerm);
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.perPage = event.pageSize;
    this.loadBlacklist(this.currentPage, this.perPage, this.searchTerm);
  }

  showCreateEditPopup(editMode: boolean, word?: string, id?: number) {
    this.isEditMode = editMode;
    this.selectedWord = word || '';
    this.selectedId = id || null;
    this.isCreateEditPopupVisible = true;
  }

  hideCreateEditPopup() {
    setTimeout(() => this.resetFormState(), 300);
    this.isCreateEditPopupVisible = false;
  }

  resetFormState() {
    this.isEditMode = false;
    this.selectedWord = '';
    this.selectedId = null;
  }

  onSaveClick() {
    this.tempIsEditMode = this.isEditMode;
    this.tempWord = this.selectedWord;
    this.tempId = this.selectedId;
    this.isCreateEditPopupVisible = false;
    const operation = this.tempIsEditMode && this.tempId
      ? this.blacklistService.update(this.tempId, this.tempWord)
      : this.blacklistService.create(this.tempWord);
    operation.subscribe({
      next: () => {
        this.loadBlacklist(this.currentPage, this.perPage, this.searchTerm);
        this.resetFormState();
        const message = this.tempIsEditMode
          ? 'La palabra se ha editado con éxito.'
          : 'La palabra se ha creado con éxito.';
        this.showSuccess(message);
      },
      error: () => {
        this.isEditMode = this.tempIsEditMode;
        this.selectedWord = this.tempWord;
        this.selectedId = this.tempId;
        this.isCreateEditPopupVisible = true;
        this.showError('Ocurrió un error al guardar la palabra.');
      },
    });
  }

  showTogglePopup(item: Blacklist) {
    this.selectedElement = item;
    this.isTogglePopupVisible = true;
  }

  hideTogglePopup() {
    setTimeout(() => (this.selectedElement = null), 300);
    this.isTogglePopupVisible = false;
  }

  onConfirmToggleClick() {
    if (!this.selectedElement) return;
    this.tempElement = this.selectedElement;
    this.isTogglePopupVisible = false;
    const operation = this.tempElement.isActive
      ? this.blacklistService.delete(this.tempElement.id, 'true')
      : this.blacklistService.delete(this.tempElement.id, 'false');
    operation.subscribe({
      next: () => {
        this.loadBlacklist(this.currentPage, this.perPage, this.searchTerm);
        this.selectedElement = null;
        const msg = this.tempElement?.isActive
          ? 'La palabra ha sido desactivada con éxito.'
          : 'La palabra ha sido activada con éxito.';
        this.showSuccess(msg);
      },
      error: () => {
        this.selectedElement = this.tempElement;
        this.isTogglePopupVisible = true;
        this.showError(this.tempElement?.isActive
          ? 'No se pudo desactivar la palabra.'
          : 'No se pudo activar la palabra.');
      }
    });
  }

  getType(type: string) {
    const currentType = type.replace('-Filter', '');
    console.log(`Clicked filter: ${currentType}, Current type: ${this.type}`);

    if (this.type === currentType) {
      // Reset filter si se hace clic en el filtro ya activo
      this.type = '';
      this.statusFilter = '';
      this.saveFilters();
      this.loadBlacklist();
      console.log(`Filters reset: type=${this.type}, statusFilter=${this.statusFilter}`);
    } else {
      if (type === 'Activos-Filter') {
        this.statusFilter = 'active';
        this.type = 'Activos';
      } else if (type === 'Inactivos-Filter') {
        this.statusFilter = 'inactive';
        this.type = 'Inactivos';
      } else if (type === 'Todos-Filter') {
        this.statusFilter = 'all';
        this.type = 'Todos';
      }
      this.saveFilters();
      this.filterByStatus(this.statusFilter);
      console.log(`Filters applied: type=${this.type}, statusFilter=${this.statusFilter}`);
    }
  }

  saveFilters() {
    // Guardamos todo el estado actual en localStorage
    this.filter = {
      searchTerm: this.searchTerm,
      currentPage: this.currentPage,
      perPage: this.perPage,
      statusFilter: this.statusFilter,
      type: this.type
    };
    localStorage.setItem('blacklistFilters', JSON.stringify(this.filter));
  }

  showSuccess(message: string) {
    this.messageService.add({
      severity: 'success',
      summary: 'Éxito',
      detail: message,
      life: 3000,
    });
  }

  showError(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message,
      life: 3000,
    });
  }
}
