<p-dialog
  header="Tipos de Proveedor"
  [(visible)]="displayListDialog"
  [modal]="true"
  [closable]="false"
  [resizable]="false"
  [draggable]="false"
  [position]="'center'"
  [baseZIndex]="2100"
  [contentStyle]="{ 'max-height': '350px', overflow: 'hidden' }"
  [styleClass]="'custom-dialog'"
>
  <ng-template pTemplate="header">
    <span class="popup-title">Tipos de Proveedor</span>
    <button type="button" class="close-button" (click)="closeListDialog(true)">
      &times;
    </button>
  </ng-template>

  <ng-template pTemplate="content">
    <div class="item-list">
      <div *ngFor="let supplierType of paginatedSupplierTypes" class="item">
        <span>{{ supplierType.name }}</span>
        <button class="icon-btn edit" (click)="openFormDialog(supplierType)">
          <mat-icon>edit</mat-icon>
        </button>
        <button class="icon-btn delete" (click)="confirmDeleteDialog(supplierType)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <div class="item-button">
      <button class="btn primary" (click)="openFormDialog()">Nuevo</button>
      <button class="btn secondary" (click)="closeListDialog(true)">Cerrar</button>
    </div>
  </ng-template>
  <p-toast/>
</p-dialog>
  <!-- Popup para crear/editar -->
  <p-dialog
    [(visible)]="displayFormDialog"
    [header]="dialogTitle"
    [modal]="true"
    [closable]="true"
    [resizable]="false"
    [draggable]="false"
    [position]="'center'"
    [contentStyle]="{ minHeight: '90px', overflow: 'hidden' }"
    [breakpoints]="{ '960px': '75vw', '640px': '90vw' }"
    [focusOnShow]="true"
    [dismissableMask]="true"
  >
    <ng-template pTemplate="header">
      <span class="popup-title">{{ dialogTitle }}</span>
    </ng-template>

    <ng-template pTemplate="content">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="input-box">
          <label for="name">Nombre del Tipo de Proveedor</label>
          <input
            id="name"
            type="text"
            formControlName="name"
            placeholder="Ingrese el nombre"
          />
        </div>
      </form>
    </ng-template>

    <ng-template pTemplate="footer">
      <button class="btn secondary" (click)="closeFormDialog()">Regresar</button>
      <button class="btn primary" (click)="onSubmit()" [disabled]="form.invalid">Guardar</button>
    </ng-template>
    <p-toast/>
  </p-dialog>


  <!-- Popup de confirmación para eliminar -->
  <p-dialog
    header="Confirmar Eliminación"
    [(visible)]="displayConfirmDialog"
    [modal]="true"
    [closable]="false"
    [resizable]="false"
    [draggable]="false"
    [position]="'center'"
    [baseZIndex]="2100"
    [contentStyle]="{ 'max-width': '400px', 'text-align': 'center' }"
    [styleClass]="'confirm-dialog'"
  >
    <ng-template pTemplate="content">
      <div class="confirmation-container">
        <p class="confirmation-message">
          ¿Estás seguro de que deseas eliminar el tipo de proveedor <b>"{{ selectedSupplierName }}"</b>?
        </p>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button class="btn secondary" (click)="closeConfirmDialog()">Regresar</button>
      <button class="btn danger" (click)="confirmDelete()">Eliminar</button>
    </ng-template>
    <p-toast/>
  </p-dialog>




