import {Component, OnInit} from '@angular/core';
import {catchError, firstValueFrom, Subject, throwError} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {EventService} from "../../../events/services/event.service";
import {FilesService} from "../../../../services/files.service";
import {getErrorMessage} from "../../../../helpers/validators.helper";
import {Employee} from "../../../../interfaces/employee.interface";
import {AccountService} from "../../services/account.service";
import {getLinkImage} from "../../../../helpers/converters.helper";
import {UserService} from "../../../../services/user.service";
import { ChangePasswordComponent } from 'src/app/pages/change-password/pages/change-password.component';
import { DialogService } from 'primeng/dynamicdialog';
import { regex } from 'src/app/helpers/input-group.helper';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import UserDataLogin from "../../../../interfaces/user-data-login.interface";
import {ImageAccountService} from "../../services/image-account.service";

@Component({
  selector: 'app-reunion-socios-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss'],
  providers: [DialogService, MessageService],
})

export class AccountEditComponent implements OnInit {

  notifications: Map<string, string> = new Map<string, string>();
  account!: Employee;
  form!: FormGroup;
  image = ''
  imageRecieve?: File;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private eventService: EventService,
    private filesService: FilesService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private userService: UserService,
    private imageAccountService: ImageAccountService,
    private readonly dialogService: DialogService,
    private messageService: MessageService
  ) {
  }

  async ngOnInit() {
    this.account = await this.getAccount();
    this.form = this.createFormWithValidation(this.account);
    const localUserData = this.imageAccountService.getUserDataFromLocalStorage();
    this.image = localUserData?.image
      ? getLinkImage(localUserData.image)
      : this.account.image
        ? getLinkImage(this.account.image)
        : '';
  }

  async getAccount() {
    try {
      const response = await firstValueFrom(this.accountService.getOne())
      if (response.ok) {
        return response.body.data
      }
      return null
    } catch (error) {
      return null;
    }
  }

  update(data: any) {
    try {
      return this.accountService.update(data);
    } catch (error) {
      console.error("Error al actualizar los datos:", error);
      throw error;
    }
  }

  updateUserData(newImageUrl: string): void {
    const userData = { ...this.imageAccountService.getUserDataFromLocalStorage(), image: newImageUrl };
    this.imageAccountService.updateUserData(userData);
  }

  getUserData(): UserDataLogin | undefined {
    const jsonData = localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    return userData?.data;
  }

  onImageSelected(file: File) {
    this.imageRecieve = file;

    const previousImageUrl = this.image;

    const userData = this.getUserData();
    const imageUrl = URL.createObjectURL(file);
    this.image = imageUrl;

    this.imageAccountService.updateUserData({ image: imageUrl });

    window.addEventListener('beforeunload', () => {
      if (!this.imageRecieve) {
        this.image = previousImageUrl;
        this.imageAccountService.updateUserData({ image: previousImageUrl });
      }
    });
  }

  async onSubmit() {
    if (this.form.valid) {
      this.saveImageAndSendData();
      this.notifications.clear();
    }
  }

  saveImageAndSendData() {
    if (this.form.valid) {
      if (this.imageRecieve) {
        this.filesService
          .uploadImage(this.imageRecieve)
          .pipe(
            catchError((error) => {
              this.notifications.set(
                'image',
                'Hubo un error al subir la imagen, intentelo mas tarde'
              );
              return throwError(() => error);
            })
          )
          .subscribe((data) => {
            if (data.status === 200 || data.status === 201) {
              this.notifications.clear();
              const imageUrl = data.body.path;
              this.form.patchValue({ image: imageUrl })
              this.imageAccountService.updateUserData({ image: imageUrl });
              this.sendFormData();
            }
          });
      } else {
        this.sendFormData();
      }
    }
  }

  sendFormData() {
    if (this.form.valid) {
      this.accountService.update(this.form.value).subscribe(response => {
        this.messageService.add({severity: 'success', summary: 'Operación exitosa', detail: response.body.message});
      });
      this.updateInfoUserLocalStorage();
    }
  }

  async updateInfoUserLocalStorage() {
    const {name, fatherName, image} = this.form.value;
    const fullName = `${name} ${fatherName}`;
    this.userService.setNameUser(fullName, image);
  }

  createFormWithValidation(data: Employee) {
    return this.formBuilder.group({
      name: [data.name, [Validators.compose([Validators.required, Validators.pattern(regex.letter), Validators.maxLength(100)])]],
      fatherName: [data.fatherName, [Validators.compose([Validators.required, Validators.pattern(regex.letter), Validators.maxLength(100)])]],
      motherName: [data.motherName, [Validators.compose([Validators.required, Validators.pattern(regex.letter), Validators.maxLength(100)])]],
      phone: [data.phone, [Validators.compose([Validators.pattern(regex.phone), Validators.minLength(9)])]],
      email: [data.email, []],
      image: [data.image, []]
    });
  }

  protected readonly getErrorMessage = getErrorMessage;

  showChangePassword() {
    this.dialogService.open(ChangePasswordComponent, {
      data: null,
      header: 'Cambio de contraseña',
      style: {
        width: '40%',
        height: 'auto',
      },
    });
  }

  get isFormValid(): boolean {
    if (this.form.valid) {
      this.notifications.clear();
    } else {
      this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    }
    return this.form.valid;
  }
}
