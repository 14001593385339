import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageAccountService {
  private userDataSubject = new BehaviorSubject<any>(this.getUserDataFromLocalStorage());
  public userData$ = this.userDataSubject.asObservable();

  constructor() {}

  getUserDataFromLocalStorage(): any {
    const data = localStorage.getItem('UserData');
    return data ? JSON.parse(data).data : null;
  }

  updateUserData(userData: any): void {
    const currentData = localStorage.getItem('UserData');
    const newUserData = currentData ? {...JSON.parse(currentData), data: {...JSON.parse(currentData).data, ...userData}} : { data: userData };
    localStorage.setItem('UserData', JSON.stringify(newUserData));
    this.userDataSubject.next(newUserData.data);
  }
}
