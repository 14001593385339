import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SuppliersService } from '../../../services/suppliers.service';
import { Rating, SelectOption, Supplier, SupplierSave, SupplierType } from '../../../interfaces/supplier.interface';
import { defaultSupplier } from 'src/app/helpers/supplier.helper';
import { NgForm } from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import UserDataLogin from 'src/app/interfaces/user-data-login.interface';
import { getLinkImage } from 'src/app/helpers/converters.helper';
import {ReviewsService} from "../../../services/reviews.service";

@Component({
  selector: 'app-supplier-form',
  templateUrl: './supplier-form.component.html',
  styleUrls: ['./supplier-form.component.scss'],
  providers: [
    MessageService,
    ConfirmationService,
  ],
})
export class SupplierFormPageComponent implements OnInit {
  @ViewChild('form', { static: false }) public form!: NgForm;
  notifications: Map<string, string> = new Map<string, string>();
  isEditMode: boolean = false;
  supplierId: number = 0;
  supplier!: Supplier;
  isPopupVisible: boolean = false;
  selectOptionsType: SelectOption[] = [];
  sizeAvatar = 'md';
  isConfirmPopupVisible: boolean = false;
  isRatingPopupVisible: boolean = false;
  filteredRatings: Rating[] = [];
  currentFilter: number | null = null
  isDropdownOpen = false;
  isAdmin: boolean = false;
  isWriter: boolean = false;

  selectedStarFilter: number | null = null;

  starOptions = [
    { value: 1, label: '1 Estrella' },
    { value: 2, label: '2 Estrellas' },
    { value: 3, label: '3 Estrellas' },
    { value: 4, label: '4 Estrellas' },
    { value: 5, label: '5 Estrellas' }
  ];

  getLinkImage = getLinkImage;

  ratingPopupTitle: string = '';
  selectedRating: number = 0;
  mockComment: string = '';
  ratings: Rating[] = [];

  editingRating: any = null;

  showSupplierTypeDialog: boolean = false;
  currentRatingToDelete: Rating | null = null;
  showRatingDialog: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private readonly suppliersService: SuppliersService,
    private readonly messageService: MessageService,
    private readonly confirmationService: ConfirmationService
    // DialogService eliminado
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.isEditMode = true;
        this.supplierId = +params['id'];
        this.getSupplier(this.supplierId);
      } else {
        this.isEditMode = false;
        this.supplier = defaultSupplier();
        this.filteredRatings = this.supplier.ratings;
      }
    });

    this.getSupplierType();
    this.setAdminRole();
    this.setWriterRole();
  }

  getSupplierType() {
    this.suppliersService.getSupplierTypes().subscribe((supplierTypes: SupplierType[]) => {
      this.selectOptionsType = supplierTypes
        .filter((supplierType: SupplierType) => supplierType.isActive)
        .map((supplierType: SupplierType) => ({
          value: supplierType.id,
          label: supplierType.name,
        }));
    });
  }

  setAdminRole() {
    const userData = this.getUserData();
    this.isAdmin = userData?.roles.some((role) => role.name === 'admin') || false;
  }

  setWriterRole() {
    const userData = this.getUserData();
    this.isWriter = userData?.roles.some((role) => role.name === 'writer') || false;
  }

  getUserData(): UserDataLogin | undefined {
    const jsonData = localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    return userData?.data;
  }

  getSupplier(supplierId: number) {
    this.suppliersService.getOne(supplierId).subscribe((supplier: Supplier) => {
      const data = this.getUserData();
      supplier?.ratings?.forEach((rating: Rating) => {
        rating.isEdit = data?.id === rating.user.id;
      });
      this.supplier = supplier;
      this.filteredRatings = supplier.ratings;
    });
  }

  updateRating(rating: Rating): void {
    this.editingRating = rating;
    this.showRatingDialog = true;
  }

  createRating(): void {
    this.editingRating = null;
    this.showRatingDialog = true;
  }

  onRatingDialogClose(success: boolean): void {
    this.showRatingDialog = false;
    this.editingRating = null;
    if (success) {
      this.getSupplier(this.supplierId);
      this.showSuccess('Calificación registrada con éxito.');
    }
  }

  onStarFilterChange(event: any): void {
    const selectedValue = event.value;
    this.filterRatings(selectedValue);
  }

  filterRatings(star: number | null): void {
    this.currentFilter = star;
    if (star !== null) {
      this.filteredRatings = this.supplier.ratings.filter(rating => rating.number === star);
    } else {
      this.filteredRatings = [...this.supplier.ratings];
    }
  }



  toBack(): void {
    this.router.navigate(['/guia-proveedores']);
  }

  onSelectedValueChange(newValue: string | number): void {
    this.supplier.supplierTypeId = Number(newValue);
  }

  updateImage(event: string) {
    this.supplier.image = event;
  }

  errorLoadImage(event: string) {
    this.notifications.set('errorLoadImage', event);
  }

  save(supplier: Supplier) {
    this.notifications.clear();
    const supplierCreated = new SupplierSave(supplier);

    if (supplier.id === 0) {
      this.suppliersService.create(supplierCreated).subscribe({
        next: () => {
          this.form.reset();
          this.showSuccess('Proveedor creado.');
        },
        error: () => {
          this.notifications.set('not-save', 'Hubo un error, intentelo nuevamente');
        }
      });
    } else {
      if (!supplier.isActive) {
        this.confirmationService.confirm({
          message: '¿Está seguro de que desea desactivar este proveedor?',
          header: 'Confirmar desactivación',
          icon: 'pi pi-exclamation-triangle',
          defaultFocus: 'none',
          acceptButtonStyleClass: 'custom-accept-btn',
          rejectButtonStyleClass: 'custom-reject-btn',
          accept: () => {
            this.updateSupplier(supplierCreated);
          },
          reject: () => {
            supplier.isActive = true;
          }
        });
      } else {
        this.updateSupplier(supplierCreated);
      }
    }
  }

private updateSupplier(supplier: SupplierSave) {
  this.suppliersService.update(supplier).subscribe({
    next: () => this.showSuccess('Proveedor actualizado.'),
    error: () => this.notifications.set('not-save', 'Hubo un error, intentelo nuevamente'),
  });
}

  newSupplierType() {
    this.showSupplierTypeDialog = true;
  }

  onSupplierTypeDialogClose(success: boolean) {
    this.showSupplierTypeDialog = false;
    if (success) {
      this.getSupplierType();
    }
  }

  onChangeSliderState(event: boolean) {
    this.supplier.isActive = event;
  }

  showSuccess(message: string) {
    this.messageService.add({ severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000 });
  }

  getStars(value: number) {
    return '⭐️'.repeat(value);
  }

  getLabel(value: number) {
    const option = this.starOptions.find(option => option.value === value);
    return option ? option.label : 'Todas las Calificaciones';
  }

  deleteRating(rating: Rating): void {
    if (!this.isAdmin) return;
    if (!rating) return;

    const supplierId = this.supplierId;
    const supplierRatingId: number = rating.supplierRatingId ?? 0;

    this.suppliersService.removeRating(supplierId, supplierRatingId).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Éxito',
          detail: 'Calificación eliminada con éxito!',
          life: 3000,
        });

        this.supplier.ratings = this.supplier.ratings.filter(r => r.supplierRatingId !== supplierRatingId);
        this.filteredRatings = this.filteredRatings.filter(r => r.supplierRatingId !== supplierRatingId);
      },
      error: () => this.showError('No se pudo eliminar la calificación.'),
    });
  }

  confirmDeleteRating(rating: Rating): void {
    if (!this.isAdmin) return;
    this.confirmationService.confirm({
      message: '¿Está seguro de que desea eliminar esta calificación?',
      header: 'Confirmar eliminación',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'none',
      acceptButtonStyleClass: 'custom-accept-btn',
      rejectButtonStyleClass: 'custom-reject-btn',
      accept: () => this.deleteRating(rating)
    });
  }


  showError(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message,
      life: 3000,
    });
  }
}
