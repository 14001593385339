<div class="page container_section_large" #top>
  <asei-title-section
    title="Lista Negra"
    description="Gestiona las palabras en la lista negra"
    actionText='Nueva Palabra'
    [hasPermission]="true"
    version="two"
    (actionClick)="showCreateEditPopup(false)"
  ></asei-title-section>

  <p-toast></p-toast>
  <asei-notifications [notifications]="notifications"></asei-notifications>

  <div class="main">
    <div class="container_filter">
      <div class="filter_button">
        <p>Filtrar por:</p>
        <button
          [ngClass]="{'active': type === 'Activos', 'inactive': type !== 'Activos'}"
          (click)="getType('Activos-Filter')"
          class="btn">
          Activos
        </button>
        <button
          [ngClass]="{'active': type === 'Inactivos', 'inactive': type !== 'Inactivos'}"
          (click)="getType('Inactivos-Filter')"
          class="btn">
          Inactivos
        </button>
        <button
          [ngClass]="{'active': type === 'Todos', 'inactive': type !== 'Todos'}"
          (click)="getType('Todos-Filter')"
          class="btn">
          Todos
        </button>
      </div>
      <div class="search">
        <input
          type="text"
          placeholder="Buscar palabra"
          [(ngModel)]="searchTerm"
          (input)="applySearch()"
        />
      </div>
    </div>

    <div class="table-container">
      <table mat-table [dataSource]="filteredBlacklist" matSort>
        <ng-container matColumnDef="word">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Palabra</th>
          <td mat-cell *matCellDef="let element">{{ element.word }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Estado</th>
          <td mat-cell *matCellDef="let element">
            <span
              class="status-circle"
              [ngClass]="element.isActive ? 'status-active' : 'status-inactive'"
            ></span>
            <span>{{ element.isActive ? 'Activo' : 'Inactivo' }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="actions-column">
            <!-- Botón de Editar -->
            <button
              class="action-btn edit"
              (click)="showCreateEditPopup(true, element.word, element.id)"
            >
              <mat-icon>edit</mat-icon>
            </button>

            <!-- Botón de Activar o Desactivar -->
            <button
              class="action-btn"
              [ngClass]="element.isActive ? 'deactivate' : 'activate'"
              (click)="showTogglePopup(element)"
            >
              <mat-icon>{{ element.isActive ? 'stop' : 'play_arrow' }}</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['word', 'status', 'actions']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['word', 'status', 'actions']"></tr>
      </table>

      <mat-paginator
        [length]="totalItems"
        [pageIndex]="currentPage - 1"
        [pageSize]="perPage"
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons
        (page)="onPageChange($event)"
      ></mat-paginator>
    </div>
  </div>

  <!-- Popup de Crear/Editar -->
  <div class="popup-overlay" [class.active]="isCreateEditPopupVisible">
    <div class="popup-content">
      <button class="close-btn" (click)="hideCreateEditPopup()">
        <mat-icon>close</mat-icon>
      </button>
      <h2>{{ isEditMode ? 'Editar Palabra' : 'Nueva Palabra' }}</h2>
      <div class="input-box">
        <label for="word">Palabra</label>
        <input id="word" type="text" [(ngModel)]="selectedWord"/>
      </div>
      <div class="popup-actions">
        <button class="cancel-btn" (click)="hideCreateEditPopup()">Regresar</button>
        <button class="confirm-btn" (click)="onSaveClick()">Guardar</button>
      </div>
    </div>
  </div>

  <!-- Popup de Confirmación -->
  <div class="popup-overlay" [class.active]="isTogglePopupVisible">
    <div class="popup-content popup-confirmation">
      <button class="close-btn" (click)="hideTogglePopup()">
        <mat-icon>close</mat-icon>
      </button>
      <h2>Confirmación</h2>
      <p>
        ¿Desea
        <span>{{ selectedElement?.isActive ? 'desactivar' : 'activar' }}</span>
        la palabra "<strong>{{ selectedElement?.word }}</strong>"?
      </p>
      <div class="popup-actions">
        <button class="cancel-btn" (click)="hideTogglePopup()">Regresar</button>
        <button class="confirm-btn" (click)="onConfirmToggleClick()">Confirmar</button>
      </div>
    </div>
  </div>
</div>
