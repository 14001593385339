import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTableModule} from '@angular/material/table';


import {PagesRoutingModule} from './pages-routing.module';
import {PagesComponent} from './pages.component';
import {ComponentsModule} from "../components/components.module";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {HomeComponent} from './home/home.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AseiDirectoryShowPageComponent} from "./asei-directory/pages/show/asei-directory-show-page.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {AseiDirectoryFormPageComponent} from "./asei-directory/pages/form/asei-directory-form-page.component";
import {MenuItemLinkComponent} from "../components/menu-item-link/menu-item-link.component";
import {ModulePageComponent} from "./modules/pages/list/module.component";
import {TreeTableModule} from "primeng/treetable";
import {TableModule} from "primeng/table";
import {ButtonModule} from "primeng/button";
import {TooltipModule} from "primeng/tooltip";
import { ToastModule } from "primeng/toast";
import { DialogModule } from 'primeng/dialog';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ListShortcutsBigCard} from "./list-shortcuts-big-card/list-shortcuts-big-card";
import {
  AffiliateDirectoryAseiListPageComponent
} from "./affiliate-directory-asei/pages/list/affiliate-directory-asei-list-page.component";
import {TechnicalAssistancePageComponent} from "./technical-assistance/pages/form/technical-assistance-page.component";
import {PostListPageComponent} from "./post/pages/list/post-list-page.component";
import {PostFormPageComponent} from "./post/pages/form/post-form-page.component";
import {PostShowPageComponent} from "./post/pages/show/post-show-page.component";
import {ReportListPageComponent} from "./reports/pages/list/report-list-page.component";
import {ReportFormPageComponent} from "./reports/pages/form/report-form-page.component";
import { ReportShowPageComponent } from './reports/pages/show/report-show.component';
import {EventSimpleListPageComponent} from "./events/pages/list/simple/event-simple-list-page.component";
import {EventsFormPageComponent} from "./events/pages/form/events-form-page.component";
import {EventShowPageComponent} from "./events/pages/show/event-show-page.component";
import {EventAnnualListPageComponent} from "./events/pages/list/annual/event-annual-list-page.component";
import {EventMonthlyListPageComponent} from "./events/pages/list/monthly/event-monthly-list-page.component";
import {
  AffiliateDirectoryFormPageComponent
} from "./affiliate-directory-asei/pages/form/affiliate-directory-form-page.component";
import {PagesFormPageComponent} from "./modules/pages/form/pages-form-page/pages-form-page.component";
import {RadioButtonModule} from "primeng/radiobutton";
import { CheckboxModule } from 'primeng/checkbox';
import {FileUploadModule} from "primeng/fileupload";
import {ModulesFormPageComponent} from "./modules/pages/form/modules-form-page/modules-form-page.component";
import {PickListModule} from "primeng/picklist";
import {DropdownModule} from "primeng/dropdown";
import { MultiSelectModule } from 'primeng/multiselect';
import { ChangePasswordComponent } from './change-password/pages/change-password.component';
import { PopupListComponent } from './popups/pages/list/popup.component';
import { EventsFormComponent } from './events-activities/events-form/events-form.component';

import { GalleriaModule } from 'primeng/galleria';
import { CdkDrag, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';
import { SubPagesFormPageComponent } from './modules/pages/form/sub-pages-form-page/sub-pages-form-page.component';
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import { SupplierListPageComponent } from './supplier-guide/pages/suppliers/list/supplier.component';
import { SupplierFormPageComponent } from './supplier-guide/pages/suppliers/form/supplier-form.component';
import {RatingModule} from "primeng/rating";
import {BlacklistListComponent} from "./blacklist/pages/list/blacklist-list.component";
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorModule} from "@angular/material/paginator";
import { SupplierTypeListComponent } from './supplier-guide/pages/supplier-types/list/supplier-type.component';
import {RatingComponent} from "./supplier-guide/components/rating/rating.component";

@NgModule({
  declarations: [
    PagesComponent,
    HomeComponent,
    AseiDirectoryShowPageComponent,
    AseiDirectoryFormPageComponent,
    ModulePageComponent,
    PopupListComponent,
    ListShortcutsBigCard,
    AffiliateDirectoryAseiListPageComponent,
    TechnicalAssistancePageComponent,
    PostListPageComponent,
    PostFormPageComponent,
    PostShowPageComponent,
    ReportListPageComponent,
    ReportFormPageComponent,
    ReportShowPageComponent,
    EventSimpleListPageComponent,
    EventsFormPageComponent,
    EventShowPageComponent,
    EventAnnualListPageComponent,
    EventMonthlyListPageComponent,
    AffiliateDirectoryFormPageComponent,
    PagesFormPageComponent,
    SubPagesFormPageComponent,
    ModulesFormPageComponent,
    ChangePasswordComponent,
    EventsFormComponent,
    SupplierTypeListComponent,
    SupplierListPageComponent,
    SupplierFormPageComponent,
    BlacklistListComponent,
    RatingComponent
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    ComponentsModule,
    MatToolbarModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatExpansionModule,
    MenuItemLinkComponent,
    TreeTableModule,
    TableModule,
    ButtonModule,
    TooltipModule,
    ToastModule,
    DialogModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    RadioButtonModule,
    CheckboxModule,
    FileUploadModule,
    PickListModule,
    DropdownModule,
    MultiSelectModule,
    GalleriaModule,
    MatTableModule,
    MatIconModule,
    DragDropModule,
    CdkDrag,
    CdkDropList,
    MatMenuModule,
    MatButtonModule,
    RatingModule,
    MatSortModule,
    MatPaginatorModule
  ]
})
export class PagesModule {
}
