import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SuppliersService } from '../../../services/suppliers.service';
import { SupplierList } from '../../../interfaces/supplier.interface';
import { PaginatorComponent } from 'src/app/components/paginator/paginator.component';
import { PaginatorData } from 'src/app/interfaces/paginator-data.interface';
import { SupplierTypesService } from '../../../services/supplier-types.service';
import { SupplierType } from '../../../interfaces/supplier-type.interface';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss'],
})
export class SupplierListPageComponent implements OnInit, OnDestroy {
  suppliers: SupplierList[] = [];
  supplierTypes: SupplierType[] = [];
  searchByFullName: string = '';
  paginatorData!: PaginatorData;
  perPage = 10;
  type = '';
  page: string = '1';
  isAdmin: boolean = false;
  isWriter: boolean = false;
  filter: any;
  slug!: string;

  @ViewChild('pagination') pagination!: PaginatorComponent;

  constructor(
    private readonly suppliersService: SuppliersService,
    private readonly supplierTypesService: SupplierTypesService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    localStorage.removeItem('supplierFilters');

    this.route.url.subscribe(segments => {
      this.slug = segments.join('/');
      this.initializeFilters();
    });

    const userDataJSON = localStorage.getItem('UserData');
    if (userDataJSON) {
      const userData = JSON.parse(userDataJSON);
      this.isAdmin = userData.data.roles.some((role: any) => role.name === 'admin');
      this.isWriter = userData.data.roles.some((role: any) => role.name === 'writer');
    }

    this.loadSuppliers();
    this.loadSupplierTypes();
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.clearLocalStorageOnReload);
  }

  initializeFilters(): void {
    const savedFilters = localStorage.getItem('supplierFilters');
    if (savedFilters) {
      this.filter = JSON.parse(savedFilters);
      this.type = this.filter.type || '';
      this.searchByFullName = this.filter.search || '';
      this.page = this.filter.page || '1';
      this.perPage = this.filter.perPage || this.perPage;
    }
  }

  loadSuppliers(): void {
    this.suppliersService.callGetList(this.perPage, this.type, 'asc', this.searchByFullName, this.page).subscribe({
      next: (response) => {
        if (response.ok) {
          this.suppliers = response.body.data;
          this.paginatorData = {
            links: response.body.links,
            meta: response.body.meta,
          };
          this.page = this.paginatorData.meta.current_page.toString();
          this.saveFilters();
        }
      },
      error: () => {
        console.error('Error al cargar los proveedores');
      },
    });
  }

  loadSupplierTypes(): void {
    this.supplierTypesService.callGetList().subscribe({
      next: () => {
        this.supplierTypesService.getList().subscribe((types) => {
          this.supplierTypes = types;
        });
      },
      error: () => {
        console.error('Error al cargar los tipos de proveedores');
      },
    });
  }

  getType(supplierType: SupplierType): void {
    if (this.type === supplierType.id.toString()) {
      this.type = '';
    } else {
      this.type = supplierType.id.toString();
    }
    this.page = '1';
    this.saveFilters();
    this.loadSuppliers();
  }

  onFilter(): void {
    this.page = '1';
    this.saveFilters();
    this.loadSuppliers();
  }

  clearSearchInput(): void {
    this.searchByFullName = '';
    this.page = '1';
    this.saveFilters();
    this.loadSuppliers();
  }

  onPageSelected(url: string): void {
    const urlParams = new URLSearchParams(url);
    const pageParam = urlParams.get('page');
    this.page = pageParam || '1';
    this.saveFilters();
    this.loadSuppliers();
  }

  saveFilters(): void {
    this.filter = {
      type: this.type,
      search: this.searchByFullName,
      page: this.page,
      perPage: this.perPage,
    };
    localStorage.setItem('supplierFilters', JSON.stringify(this.filter));
  }

  clearLocalStorageOnReload(): void {
    localStorage.removeItem('supplierFilters');
  }

  redirect(id: number): void {
    this.router.navigate([`${this.slug}/${id}/editar`]);
  }
}
