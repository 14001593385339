import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { NgForm } from '@angular/forms';
import { firstValueFrom } from "rxjs";
import { PermissionsService } from 'src/app/services/permissions.service';
import { Module } from "../../interfaces/module.interface";
import { ModuleService } from "../../pages/modules/services/module.service";
import { RouteService } from "../../services/route.service";
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare let gtag: (property: string, value: any, configs: any) => {};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginUser = {
    email: '',
    password: '',
    remember: false,
  };
  showPassword = false;
  passwordToggleIcon = 'visibility_off';
  isShowLoaderButton = false;
  notifications: Map<string, string> = new Map<string, string>();
  rememberToken: string = '';

  showLogin: boolean = true;

  constructor(private router: Router,
    private authService: AuthService,
    private permissionsService: PermissionsService,
    private moduleService: ModuleService,
    private routeService: RouteService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {
  }

  ngOnInit() {
    const jsonData = localStorage.getItem('updateAssociate');
    if (jsonData) {
      const updateAssociate = jsonData ? JSON.parse(jsonData) : undefined;
      if (updateAssociate == true) {
        this.notifications.set('login', 'Tus nuevas credenciales fueron envíadas a tu nuevo correo ingresado.');
      }
    }

    const rememberTokenString = localStorage.getItem('rememberToken');
    this.rememberToken = rememberTokenString ? JSON.parse(rememberTokenString) : null;
    this.showLogin = this.rememberToken != '' ? false : true;
  }

  showLoginForm() {
    this.showLogin = true;
  }

  login(fLogin: NgForm) {
    if (fLogin.invalid) return;

    if (this.showLogin) {
      localStorage.removeItem('updateAssociate');
      this.notifications.clear();

      let payload = {
        email: this.loginUser.email,
        password: this.loginUser.password,
        remember: this.loginUser.remember,
        token: '',
      };

      this.recaptchaV3Service.execute('importantAction').subscribe(async (token: string) => {
        payload.token = token;
        this.isShowLoaderButton = true;

        try {
          const response = await firstValueFrom(this.authService.login(payload));
          if (response.ok) {
            this.saveRememberToken(response.body.token);
            localStorage.setItem('UserData', JSON.stringify(response.body));
            this.moduleService.getAll().subscribe({
              next: response => {
                const modules: Module[] = response?.body?.data;
                this.notifications.clear();
                this.moduleService.setModuleInLocalStorage(modules);
                this.routeService.setInitialRoutes(modules);
                this.permissionsService.loadPermissions();
                let urlStash = localStorage.getItem('currentUrl')
                if (urlStash) {
                  localStorage.removeItem('currentUrl')
                  this.router.navigate([urlStash]);
                } else {
                  this.router.navigate(['/inicio']);
                }
              },
              error: error => {
                console.error(error);
              }
            });
          } else {
            localStorage.removeItem('UserData');
            this.notifications.set('login', 'El correo y/o clave es incorrecto. Por favor, intenta nuevamente');
          }
        } catch (error) {
          // console.error("catch error", error);
          this.notifications.set('error-login', 'El correo y/o clave es incorrecto. Por favor, intenta nuevamente');
        } finally {
          this.isShowLoaderButton = false;
        }
      });
    }
  }

  rememberLogin() {
    this.recaptchaV3Service.execute('importantAction').subscribe(async (token: string) => {
      this.isShowLoaderButton = true;

        try {
          const response = await firstValueFrom(this.authService.remember(this.rememberToken));
          if (response.ok) {
            localStorage.setItem('UserData', JSON.stringify(response.body));
            this.moduleService.getAll().subscribe({
              next: response => {
                const modules: Module[] = response?.body?.data;
                this.notifications.clear();
                this.moduleService.setModuleInLocalStorage(modules);
                this.routeService.setInitialRoutes(modules);
                this.permissionsService.loadPermissions();
                let urlStash = localStorage.getItem('currentUrl')
                if (urlStash) {
                  localStorage.removeItem('currentUrl')
                  this.router.navigate([urlStash]);
                } else {
                  this.router.navigate(['/inicio']);
                }
              },
              error: error => {
                console.error(error);
              }
            });
          } else {
            localStorage.removeItem('UserData');
            this.notifications.set('login', 'El correo y/o clave es incorrecto. Por favor, intenta nuevamente');
          }
        } catch (error) {
          // console.error("catch error", error);
          this.notifications.set('error-login', 'El correo y/o clave es incorrecto. Por favor, intenta nuevamente');
        } finally {
          this.isShowLoaderButton = false;
        }
    });
  }

  backRemember() {
    this.showLogin = false;
  }

  saveRememberToken(rememberToken: any) {
    if (rememberToken) {
      localStorage.setItem('rememberToken', JSON.stringify(rememberToken));
    }
  }


  togglePassword() {
    this.showPassword = !this.showPassword;
    this.passwordToggleIcon = this.showPassword
      ? 'visibility_off'
      : 'visibility';
  }
}
