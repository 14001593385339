import { Supplier } from '../pages/supplier-guide/interfaces/supplier.interface';

export const defaultSupplier = (): Supplier => {
  return {
    id: 0,
    name: '',
    businessName: '',
    documentNumber: '',
    entryDate: '',
    image: '',
    address: '',
    isActive: true,
    web: '',
    supplierTypeId: 0,
    average_rating: 0,
    ratings: [],
  };
};
