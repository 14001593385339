<div class="page container_section_large">
  <asei-title-section
    link="/guia-proveedores/nuevo"
    title="Guía de Proveedores"
    description="Gestiona la información de los proveedores de ASEI"
    actionText="Nuevo"
    [hasPermission]="true"
    version="two"
  ></asei-title-section>

  <div class="main">
    <div class="container_filter">
      <div class="filter_button">
        <p>Filtrar por:</p>
        <button
          *ngFor="let supplierType of supplierTypes"
          class="btn"
          [ngClass]="{
            'active': type === supplierType.id.toString(),
            'inactive': type !== supplierType.id.toString()
          }"
          (click)="getType(supplierType)">
          {{ supplierType.name }}
        </button>
      </div>
      <div class="search">
        <input
          type="text"
          placeholder="Buscar proveedor por nombre"
          [(ngModel)]="searchByFullName"
          (input)="onFilter()" />
        <span class="clear-btn" *ngIf="searchByFullName" (click)="clearSearchInput()">
          <mat-icon>close</mat-icon>
        </span>
      </div>
    </div>
    <div class="container_item">
      <div class="item-list list-custom">
        <div class="custom-item" *ngFor="let supplier of suppliers">
          <asei-item-list
            [name]="supplier.name"
            [businessName]="'Calificación: ' + supplier.average_rating"
            environment="guia-proveedores"
            [urlImage]="supplier.image"
            version="inverse"
            [isSelected]="true"
            ($showMore)="redirect(supplier.id)"
            [showInactive]="!supplier.isActive && isAdmin"
          ></asei-item-list>
        </div>
      </div>
      <app-paginator
        *ngIf="paginatorData"
        [paginatorData]="paginatorData"
        (pageSelected)="onPageSelected($event)">
      </app-paginator>
    </div>
  </div>
</div>
